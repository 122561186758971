<template>
  <div class="categories">
    <Navbar/>  
    <div class="container">
        <CategorieTitle>
            <template #maintitle>
                Кулинарные школы
            </template>
        </CategorieTitle>

        <div class="row school-row" >
            <SchoolCard :url="'/schools/culinary-schools/ducasse'" :background="'image-ducasse'">
                <template #title>
                    École Ducasse
                </template>
                <template #subtitle>
                    элитная кулинарная школа под руководством легендарного шеф-повара Alain Ducasse. Кампусы в Париже и Иссинго.
                </template>
            </SchoolCard>

            <SchoolCard :url="'/schools/culinary-schools/cordon-bleu'" :background="'image-cordon-bleu'">
                <template #title>
                    Le Cordon Bleu
                </template>
                <template #subtitle>
                    элитная кулинарная школа с мировым именем, расположенная в 15-м округе Парижа.    
                </template>
            </SchoolCard>

        </div>

        <div class="row school-row">
            <SchoolCard :url="'#'" :background="'image-culinaire'">
                <template #title>
                    Institut Culinaire de France
                </template>
                <template #subtitle>
                    кулинарная школа в Бордо под руководством знаменитого Pierre Hermé.
                </template>
            </SchoolCard>
        </div>

    </div>
  </div>
</template>

<script>
import Navbar from '@/components/common/Navbar.vue'
import SchoolCard from '@/components/categories/slots/SchoolCard.vue'
import CategorieTitle from '@/components/categories/slots/CategorieTitle.vue'

export default {
  name: 'CulinarySchools',
  components: {
    Navbar,
    SchoolCard,
    CategorieTitle
  }
}
</script>
